<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <RelativeRelationForm
      v-if="!isLoading"
      :relativeRelationData="relativeRelation"
      :submitName="$t('edit')"
      v-on:addOrUpdateRelativeRelation="updateRelativeRelation()"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import RelativeRelationForm from "@/components/settings/settingsOther/relativeRelations/RelativeRelationForm.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import RelativeRelation from "@/models/settings/settingsOther/relativeRelations/RelativeRelation";

export default {
  name: "RelativeRelationEdit",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    RelativeRelationForm,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      relativeRelation: new RelativeRelation(),
    };
  },
  props: ["relativeRelationToken"],
  methods: {
    async getRelativeRelationDetails() {
      this.isLoading = true;
      this.relativeRelation.relativeRelationToken = this.relativeRelationToken;
      try {
        let response = await this.relativeRelation.getRelativeRelationDetails(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.relativeRelation.fillData(response.data.RelativeRelationsData);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
          this.$router.push({ name: "RelativeRelations" }).catch(() => {});
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.$router.push({ name: "RelativeRelations" }).catch(() => {});
      }
      this.isLoading = false;
    },
    async updateRelativeRelation() {
      this.isLoading = true;

      try {
        let response = await this.relativeRelation.addOrUpdateRelativeRelation(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$router.push({ name: "RelativeRelations" }).catch(() => {});
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getRelativeRelationDetails();
  },
};
</script>

<style scoped lang="scss"></style>
