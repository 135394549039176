<template>
  <div class="">
    <form autocomplete="off">
      <div class="my-card">
        <div class="row">
          <div class="form-label-group col-md-6">
            <input
              name="relativeRelationNameAr"
              id="relativeRelationNameAr"
              type="text"
              v-model="relativeRelationData.relativeRelationNameAr"
              class="form-control"
              placeholder=" "
              maxlength="50"
            />
            <label for="relativeRelationNameAr">{{
              $t("relativeRelations.nameAr")
            }}</label>
            <img src="@/assets/images/relativeRelations.svg" />
          </div>
          <div class="form-label-group col-md-6">
            <input
              name="relativeRelationNameEn"
              id="relativeRelationNameEn"
              type="text"
              v-model="relativeRelationData.relativeRelationNameEn"
              class="form-control"
              placeholder=" "
              maxlength="50"
            />
            <label for="relativeRelationNameEn">{{
              $t("relativeRelations.nameEn")
            }}</label>
            <img src="@/assets/images/relativeRelations.svg" />
          </div>
          <TextArea
            :className="'col-md-12'"
            :id="'relativeRelationNotes'"
            :value="relativeRelationData.relativeRelationNotes"
            :title="$t('notes')"
            :imgName="'notes.svg'"
            v-on:changeValue="
              relativeRelationData.relativeRelationNotes = $event
            "
          />
        </div>
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addOrUpdateRelativeRelation"
        >
          {{ submitName }}
        </button>
        <router-link
          :to="{
            name: 'RelativeRelations',
          }"
          class="btn btn-cancel"
        >
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import TextArea from "@/components/general/TextArea.vue";

export default {
  name: "RelativeRelationForm",
  components: {
    TextArea,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["relativeRelationData", "submitName"],
  methods: {
    async addOrUpdateRelativeRelation() {
      this.$emit("addOrUpdateRelativeRelation");
    },
  },
};
</script>

<style scoped lang="scss"></style>
